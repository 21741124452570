import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import SectionTitle from '../components/section-title'

const NotFoundPage = () => (
  <Layout hasHero={false}>
    <SEO title="Stránka nenalezena" />

    <Section variant="light">
      <Container>
        <SectionTitle as="h1">Stránka nenalezena</SectionTitle>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
